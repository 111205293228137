import React, { Suspense, useEffect, useState } from "react";
import "./App.scss";
import Footer from "./containers/Footer";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./routing/ProtectedRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";
import PublicSportsBook from "./views/pages/privatePages/sportsBook";
import ContactUs from "./views/pages/privatePages/FooterPages/ContactUs";
import PrivacyPolicy from "./views/pages/privatePages/FooterPages/PrivacyPolicy";
import ResponsibleGame from "./views/pages/privatePages/FooterPages/ResponsibleGame";
import FairPlay from "./views/pages/privatePages/FooterPages/FairPlay";
import GamesRule from "./views/pages/privatePages/FooterPages/GamesRule";
import MegaChallengeTnC from "./views/pages/privatePages/FooterPages/MegaChallengeTnC";
import RulesRegulations from "./views/pages/privatePages/FooterPages/RulesRegulations";
import Terms from "./views/pages/privatePages/FooterPages/Terms";
import BeforeLoginFooter from "./containers/BeforeLoginFooter";
import BeforeContactUs from "./views/pages/publicPages/FooterPages/BeforeContactUs";
import BeforeFairPlay from "./views/pages/publicPages/FooterPages/BeforeFairPlay";
import BeforeGamesRule from "./views/pages/publicPages/FooterPages/BeforeGamesRule";
import BeforeMegaChallengeTnC from "./views/pages/publicPages/FooterPages/BeforeMegaChallengeTnC";
import BeforeRulesRegulations from "./views/pages/publicPages/FooterPages/BeforeRulesRegulations";
import BeforePrivacyPolicy from "./views/pages/publicPages/FooterPages/BeforePrivacyPolicy";
import BeforeResponsibleGame from "./views/pages/publicPages/FooterPages/BeforeResponsibleGame";
import BeforeTerms from "./views/pages/publicPages/FooterPages/BeforeTerms";
import TestSportsBook from "./views/pages/privatePages/sportsBook/testSportsBook";
// import Bonus from "./views/pages/privatePages/bonus";
import BonusCard from "./views/pages/privatePages/bonus/bonusCard";
// import Referral from "./views/pages/privatePages/referral";
import LoaderImg from "./assets/images/Loader.gif";
// import Slots from "./views/pages/privatePages/Slots";
// import Spribe from "./views/pages/privatePages/Spribe";
import DisputeResolution from "./views/pages/privatePages/FooterPages/DisputeResolution";
import DisputeResolutions from "./views/pages/publicPages/FooterPages/DisputeResolutions";
import Payouts from "./views/pages/publicPages/FooterPages/Payouts";
import Registration from "./views/pages/publicPages/FooterPages/Registration";
import SelfExclusion from "./views/pages/publicPages/FooterPages/SelfExclusion";
import Payout from "./views/pages/privatePages/FooterPages/Payout";
import RegisterAfterLogin from "./views/pages/privatePages/FooterPages/RegisterAfterLogin";
import SelfExclusions from "./views/pages/privatePages/FooterPages/SelfExclusions";
import Wallet from "./views/pages/privatePages/Wallet";
import Referral from "./views/pages/privatePages/referral";
import Challenges from "./views/pages/privatePages/challenges";
import Header from "./containers/Header";
import HeaderAfterLogin from "./containers/HeaderAfterLogin";

// import WelcomeModal from "./views/components/welcomeModal";
import Promotion from "./views/pages/privatePages/Promotion";
import BeforePromotion from "./views/pages/publicPages/BeforePromotion";
import GatewaysList from "./views/pages/privatePages/deposit/gatewaysList";
import DepositGateway from "./views/pages/privatePages/deposit/depositGateway";

const Home = React.lazy(() => import("./views/pages/privatePages/home"));
const Games = React.lazy(() => import("./views/pages/privatePages/games"));
// const DynamicCasinoPage = React.lazy(() =>
//   import("./views/pages/privatePages/games/DynamicCasinoPage")
// );
const Cashier = React.lazy(() => import("./views/pages/privatePages/cashier"));
const LandingPage = React.lazy(() =>
  import("./views/pages/publicPages/landingPage")
);
const Deposit = React.lazy(() => import("./views/pages/privatePages/deposit"));
const Withdraw = React.lazy(() =>
  import("./views/pages/privatePages/withdraw")
);
const Register = React.lazy(() => import("./views/pages/publicPages/register"));
const Login = React.lazy(() => import("./views/pages/publicPages/login"));
const MobileNumberVerify = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumberVerify")
);
const MobileNumber = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumber")
);
const Profile = React.lazy(() => import("./views/pages/privatePages/profile"));
const Sports = React.lazy(() => import("./views/pages/privatePages/sports"));
const ExchangeLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeLobbyPage")
);
const MatchDetailPage = React.lazy(() =>
  import("./views/pages/privatePages/sports/matchDetailPage")
);
const MyBets = React.lazy(() => import("./views/pages/privatePages/myBets"));
const WithdrawList = React.lazy(() =>
  import("./views/pages/privatePages/withdraw/WithdrawList")
);
const ForgotPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword/resetPassword")
);
// const Betby = React.lazy(() => import("./views/pages/privatePages/betby"));
const DepositList = React.lazy(() =>
  import("./views/pages/privatePages/deposit/depositList")
);
const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAppDetails());
  }, []);

  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
    document.canonical = appDetails?.LOGO_URL;
  }, [appDetails]);

  const setVhUnit = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  useEffect(() => {
    setVhUnit();
    window.addEventListener("resize", setVhUnit);
    return () => {
      window.removeEventListener("resize", setVhUnit);
    };
  }, []);

  // useEffect(() => {
  //   dispatch(getAppDetails());
  // }, []);

  return (
    <Router>
      <div
        dangerouslySetInnerHTML={{
          __html: `<script type="application/ld+json">
{
	"@context": "http://schema.org/",
	"@type": "WebSite",
	"url": " https://www.daddy365.com ",
	"potentialAction": {
		"@type": "SearchAction",
		"target": "{search_term_string}",
		"query-input": "required name=search_term_string"
	}
}
</script>`,
        }}
      ></div>
      <ToastContainer
        closeButton={false}
        position="top-right"
        autoClose={1000}
      />
      {/* <WelcomeModal /> */}
      <Suspense
        fallback={
          <div className="loader-img">
            <img src={LoaderImg} alt="loader" />
          </div>
        }
      >
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoutes isAuth={isAuth}>
                <LandingPage />
              </PublicRoutes>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Home />
                {/* <Footer /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/wallet"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Wallet />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/games-all"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Games />
                <Footer />
              </ProtectedRoutes>
            }
          />
          {/* <Route
            path="/casino/:casinoslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug/:gameslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          /> */}
          <Route
            path="/cashier"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdrawList"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <WithdrawList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/mybets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MyBets />
                <Footer />
              </ProtectedRoutes>
            }
          />
          {/* <Route
            path="/deposit"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Deposit />
                <Footer />
              </ProtectedRoutes>
            }
          /> */}
          <Route
            path="/deposit/:gateway"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositGateway />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gatewaylist"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GatewaysList />
                <Footer />
                {/* <BottomNav /> */}
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit-list"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DepositList />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/sportsbook/:category"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Sports isAuth={isAuth} />
              </ProtectedRoutes>
            }
          />
          {/* <Route
            path="/Slots"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Slots />
              </ProtectedRoutes>
            }
          /> */}
          {/* <Route
            path="/Spribe"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Spribe />
              </ProtectedRoutes>
            }
          /> */}
          <Route
            path="sports"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Sports isAuth={isAuth} />
              </PublicRoutes>
            }
          />
          <Route
            path="/withdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Withdraw />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Register />
                <Footer />
              </PublicRoutes>
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path="/verify-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumberVerify />
              </PublicRoutes>
            }
          />
          <Route
            path="/mobile-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumber />
              </PublicRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Profile />
                <Footer />
              </ProtectedRoutes>
            }
          />
          {/* <Route path="test" element={<Sports />} /> */}
          <Route
            // path="/sports/exchange"
            path="/sports/:gameId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeLobbyPage />
              </ProtectedRoutes>
            }
          />
          {/* <Route path="/betby" element={<Betby isAuth={isAuth} />} /> */}
          <Route path="/promotion" element={<Promotion isAuth={isAuth} />} />
          <Route
            path="/promotions"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforePromotion />
              </PublicRoutes>
            }
          />
          <Route
            path="/detail-page/:slug/:matchId/:marketId/:sportId"
            element={
              <>
                <MatchDetailPage isAuth={isAuth} />
                {isAuth ? <Footer /> : <BeforeLoginFooter />}
              </>
            }
          />
          <Route
            path="/contactus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ContactUs />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PrivacyPolicy />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/responsiblegame"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ResponsibleGame />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/fairplay"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <FairPlay />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/gamesrule"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <GamesRule />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/megachallenge-termsandcondition"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MegaChallengeTnC />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/rulesandregulations"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <RulesRegulations />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/terms"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Terms />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/disputeresolution"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DisputeResolution />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/disputeresolutiions"
            element={
              <PublicRoutes isAuth={isAuth}>
                <DisputeResolutions />
              </PublicRoutes>
            }
          />
          <Route
            path="/payouts"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Payouts />
              </PublicRoutes>
            }
          />

          <Route
            path="/payout"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Payout />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/registerafterlogin"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <RegisterAfterLogin />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/self"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <SelfExclusions />
              </ProtectedRoutes>
            }
          />

          {/* <Route
            path="/bonus"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Bonus />
              </ProtectedRoutes>
            }
          /> */}
          <Route
            path="/referral"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Referral />
              </ProtectedRoutes>
            }
          />
          <Route path="/challenges" element={<Challenges />} />
          <Route
            path="/bonus/:id"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BonusCard />
              </ProtectedRoutes>
            }
          />
          {/* <Route path="/contactus" element={<ContactUs />} /> */}
          <Route
            path="/contactuss"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeContactUs />
              </PublicRoutes>
            }
          />
          <Route
            path="/registration"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Registration />
              </PublicRoutes>
            }
          />
          <Route
            path="/selfexclusion"
            element={
              <PublicRoutes isAuth={isAuth}>
                <SelfExclusion />
              </PublicRoutes>
            }
          />

          <Route
            path="/privacypolicys"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforePrivacyPolicy />
              </PublicRoutes>
            }
          />
          <Route
            path="/fairplays"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeFairPlay />
              </PublicRoutes>
            }
          />

          <Route
            path="/responsiblegames"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeResponsibleGame />
              </PublicRoutes>
            }
          />
          <Route
            path="/gamesrules"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeGamesRule />
              </PublicRoutes>
            }
          />
          <Route
            path="/megachallenge-termsandconditions"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeMegaChallengeTnC />
              </PublicRoutes>
            }
          />
          <Route
            path="/rulesandregulationss"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeRulesRegulations />
              </PublicRoutes>
            }
          />
          <Route
            path="/termss"
            element={
              <PublicRoutes isAuth={isAuth}>
                <BeforeTerms />
              </PublicRoutes>
            }
          />
          <Route
            path="/sportsbooktest"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <TestSportsBook />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ForgotPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResetPassword />
              </PublicRoutes>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
